import { FC, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import registerVideo1 from 'src/assets/video/register/1.mp4';
import registerVideo2 from 'src/assets/video/register/2.mp4';
import registerVideo3 from 'src/assets/video/register/3.mp4';
import registerVideo4 from 'src/assets/video/register/4.mp4';
import registerVideo5 from 'src/assets/video/register/5.mp4';

export const RegisterVideos: FC = () => {
  const ref = useRef<HTMLVideoElement>(null);
  const [active, setActive] = useState<number>(0);
  const videos = [registerVideo1, registerVideo2, registerVideo3, registerVideo4, registerVideo5];

  const handleNextVideo = (e, index?: number) => {
    if (index) {
      setActive(index);
      return;
    }

    setActive(prev => (prev === videos.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    if (ref?.current) {
      ref.current.addEventListener('ended', handleNextVideo, false);
    }
  }, [active]);

  const getVideo = useMemo(
    () => (
      <video
        key={videos[active]}
        ref={ref}
        className="w-full h-auto block max-w-[40rem]"
        autoPlay
        muted
      >
        <source src={videos[active]} type="video/mp4" />
      </video>
    ),
    [videos, active],
  );

  return (
    <div className="w-full h-full relative bg-blue-500 flex items-center justify-center">
      {getVideo}
      <div className="absolute w-full bottom-20 left-0 flex justify-center z-10">
        <div className="flex gap-8">
          {videos.map((_, i) => (
            <div
              key={`register-video-${i}`}
              className={cn(
                { '': i === active, 'cursor-pointer opacity-30': i !== active },
                'w-4 h-4 rounded-full bg-white transition-full',
              )}
              onClick={() => setActive(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
