import { FC, useState } from 'react';

import cn from 'classnames';

import { ReactComponent as QuestionIcon } from 'src/assets/icons/kit/question.svg';

interface ToolTipProps {
  name?: string;
  text: string;
  href?: string;
  classNames?: string;
}

export const ToolTip: FC<ToolTipProps> = ({ name, text, href, classNames }) => {
  const [show, setShow] = useState<boolean>(false);

  const onMouseEnter = () => setShow(true);

  const onMouseLeave = () => setShow(false);

  return (
    <div
      className={cn('flex items-center flex gap-0.5 cursor-default', classNames)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="font-gilroy-400 text-sm text-grey-100">Читать подробнее</div>
      <div className="font-gilroy-400 text-sm text-turquoise-100">{name}</div>
      <div className="w-5 h-5 rounded-full flex items-center justify-center bg-turquoise-600 relative">
        <QuestionIcon className="w-3 h-3 fill-pink-100" />
        {show ? (
          <div
            className={
              'font-gilroy-400 text-sm bg-white absolute z-20 bottom-[1.4rem] p-4 w-60 ' +
              'flex flex-col gap-1 rounded-xl drop-shadow-black-100'
            }
          >
            <div className="absolute top-full w-full h-3 " />
            <div
              className="absolute w-2 h-2 rotate-45 -bottom-1 bg-white"
              style={{ left: 'calc(50% - 4px)' }}
            />
            {text}
            {href ? (
              <a
                href={href}
                download="Инструкция"
                target="_blank"
                rel="noreferrer"
                className="text-turquoise-100"
              >
                Читать подробнее
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};
