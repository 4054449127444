import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'src/components/kit/Modal';
import { Button } from 'src/components/kit/Button';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { calculateOrderInfo } from 'src/utils';
import { fetchOrders } from 'src/store/orders/actions';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { ControlledInput } from 'src/components/kit/ControlledInput';
import { OrderProductCard } from 'src/components/Cards/OrderProductCard';
import { OrderType, ProductType, SupportProductType } from 'src/config/types';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import OrderService from 'src/services/OrderService';

import { ReactComponent as ChatIcon } from 'src/assets/icons/kit/chat.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';

interface EditOrderModalProps {
  order: OrderType | null;
  onClose: () => void;
}

export const EditOrderModal: FC<EditOrderModalProps> = ({ order, onClose }) => {
  const {
    shops: { selectedShop },
    products: { products },
    orders: { orderStatuses },
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const ref = useRef<HTMLDivElement>(null);

  const [tempGoods, setTempGoods] = useState<SupportProductType[]>([]);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  useClickOutside(ref, () => {
    setSearch('');
    setIsSearchOpen(false);
  });

  const searchProducts = useMemo(() => {
    const prods: ProductType[] = [];
    Object.keys(products).forEach(key => {
      prods.push(...products[key]);
    });

    return prods.filter(prod => prod.name.toLowerCase().includes(search.toLowerCase()));
  }, [search, products]);

  const openSearch = () => setIsSearchOpen(true);

  useEffect(() => {
    if (order) {
      setTempGoods(order.orderGoods);
    }
  }, [order]);

  if (!tempGoods || !order) {
    return <></>;
  }

  const orderParams = calculateOrderInfo(tempGoods);

  const orderInfo = [
    { name: 'Сумма к оплате:', value: `${orderParams.price} ₽` },
    { name: 'Всего товаров:', value: `${orderParams.count} шт.` },
    { name: 'Покупатель:', value: order.user.name },
  ];

  const getLine = (text, boldText, key) => (
    <div key={key} className="flex text-sm gap-1">
      <div className="font-gilroy-400">{text}</div>
      <div className="font-gilroy-600">{boldText}</div>
    </div>
  );

  const getOrders = async () => {
    if (!selectedShop) {
      return;
    }
    await dispatch(fetchOrders(selectedShop.id));
  };

  const changeAmount = (index: number, amount: number) => {
    setTempGoods(prev => {
      const tempList = [...prev];
      tempList[index] = { ...tempList[index], amount: amount < 1 ? 0 : amount };
      return tempList;
    });
  };

  const addGood = (good: ProductType) => {
    setSearch('');
    setIsSearchOpen(false);
    const goodIndex = tempGoods.findIndex(
      temp =>
        temp.goodCode.toString() === good.id.toString() ||
        temp.id.toString() === good.id.toString(),
    );

    if (goodIndex !== -1) {
      changeAmount(goodIndex, tempGoods[goodIndex].amount + 1);
      return;
    }

    const newGood: SupportProductType = {
      id: good.id,
      newPrice: good.newPrice,
      oldPrice: good.oldPrice,
      image: good.image,
      goodCode: good.code,
      goodName: good.name,
      orderId: order.id,
      removed: false,
      amount: 1,
    };

    setTempGoods(prev => [...prev, newGood]);
  };

  const cancelOrder = async () => {
    onClose();

    if (order.id !== '4') {
      await OrderService.changeOrderStatus(order.id, '4');
      await getOrders();
    }
  };

  const approveOrder = async () => {
    onClose();
    if (
      JSON.stringify(tempGoods) === JSON.stringify(order?.orderGoods) &&
      (order?.statusId || '1').toString() !== '1'
    ) {
      return;
    }

    await OrderService.changeOrder({
      orderId: order.id,
      shopId: order.orderGoods[0]?.order?.shopId || '',
      goods: tempGoods.map(good => ({ ...good, removed: good.amount < 1 })),
    });

    if (order.id !== '2') {
      await OrderService.changeOrderStatus(order.id, '2');
    }
    await getOrders();
  };

  return (
    <Modal isOpen={!!order} onClose={onClose} mobileClose noScroll>
      <div className="w-full t-sm:max-w-[32rem] mx-auto flex flex-col gap-5 t-sm:gap-8">
        <div className="w-full flex t-sm:flex-row flex-col gap-2 t-sm:gap-5 pr-2">
          <div className="t-sm:w-1/2">
            <div className="font-gilroy-600 text-xl">{order.id}</div>
            <Button
              variant="green"
              href={`/dashboard/support?userId=${order.user.id}`}
              size="small"
              classNames="w-fit h-fit mt-2 t-sm:mt-4"
            >
              Чат с покупателем
              <ChatIcon className="w-4 h-4 fill-white" />
            </Button>
          </div>
          <div>
            {orderInfo.map(info => getLine(info.name, info.value, `info-line-modal-${info.name}`))}
            {orderStatuses ? (
              <div className="flex text-sm gap-1 font-gilroy-400">
                <div className="text-grey-100">Статус</div>
                <div className={orderStatuses?.[order.statusId]?.styles}>
                  {orderStatuses?.[order.statusId]?.name}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div
          className="flex flex-col gap-2.5 overflow-auto"
          style={{ maxHeight: `calc(100vh - ${width >= 768 ? 380 : 426}px)` }}
        >
          {tempGoods.map((good, i) => {
            if (good.amount < 1) {
              return <Fragment key={`order-edit-good-${i}`} />;
            }

            return (
              <OrderProductCard
                key={`order-edit-good-${i}`}
                index={i}
                product={good}
                changeAmount={changeAmount}
              />
            );
          })}
        </div>

        <div className="w-full h-12">
          {isSearchOpen ? (
            <div className="w-full relative" ref={ref}>
              <ControlledInput
                onChange={setSearch}
                placeholder="Введите название товара"
                classNames="w-full rounded-b-none"
              />
              {isSearchOpen ? (
                <div
                  className={
                    'w-full bottom-full left-0 max-h-[20rem] z-200 border border-grey-700 ' +
                    'overflow-auto shadow-black-500 bg-white'
                  }
                >
                  {searchProducts?.length ? (
                    searchProducts.map(product => (
                      <div
                        key={`add-product-order-${product.id}`}
                        className={
                          'w-full cursor-pointer hover:bg-grey-700 px-8 py-4 truncate ' +
                          'font-gilroy-400 border-b border-grey-700'
                        }
                        onClick={() => addGood(product)}
                      >
                        {product.name}
                      </div>
                    ))
                  ) : (
                    <div className="w-full text-center py-10 font-gilroy-400">
                      Нет подходящих товаров
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ) : (
            <div
              className={
                'w-full bg-turquoise-500 gap-5 flex items-center justify-center cursor-pointer ' +
                'rounded-xl border border-dashed border-grey-100 h-full'
              }
              onClick={openSearch}
            >
              <div className="text-grey-100 font-gilroy-400">Добавить новый товар</div>
              <CloseIcon className="w-4 h-4 rotate-45 fill-grey-100" />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2.5 t-sm:gap-5 t-sm:flex-row">
          <Button
            variant="turquoise-outline"
            classNames="w-full whitespace-nowrap"
            onClick={cancelOrder}
          >
            Отменить заказ
          </Button>
          <Button variant="turquoise" classNames="w-full whitespace-nowrap" onClick={approveOrder}>
            {(order?.statusId || '1').toString() === '1' ? 'Подтвердить заказ' : 'Сохранить'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
