import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from './types';
import { fetchTariffs, fetchUser } from './actions';
import { TariffType, UserType } from 'src/config/types';
import TokenService from 'src/services/TokenService';

const initialState: UserState = {
  user: null,
  isLoadingUser: true,
  tariffs: [],
  isLoadingTariffs: true,
  error: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: state => {
      state.user = null;
    },
    setUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
      state.isLoadingUser = false;
    },
  },
  extraReducers: {
    [fetchUser.pending.type]: state => {
      state.isLoadingUser = true;
    },
    [fetchUser.fulfilled.type]: (state, action: PayloadAction<UserType>) => {
      state.isLoadingUser = false;
      state.error = '';
      state.user = { ...state.user, ...action.payload };
      TokenService.setUser(action.payload);
    },
    [fetchUser.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingUser = false;
      state.error = action.payload;
    },
    [fetchTariffs.pending.type]: state => {
      state.isLoadingTariffs = true;
      state.tariffs = [];
    },
    [fetchTariffs.fulfilled.type]: (state, action: PayloadAction<TariffType[]>) => {
      state.isLoadingTariffs = false;
      state.error = '';
      state.tariffs = action.payload?.sort((a, b) => +a?.id - +b?.id);
    },
    [fetchTariffs.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingTariffs = false;
      state.error = action.payload;
    },
  },
});

export const { logout, setUser } = userSlice.actions;

const reducer = userSlice.reducer;
export default reducer;
