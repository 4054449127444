import { BaseSyntheticEvent, FC, ReactNode } from 'react';
import { Logo } from 'src/components/kit/Logo';
import LogoIcon from 'src/assets/icons/logo.svg';

import cn from 'classnames';

interface AuthFromContainerProps {
  onSubmit: (e?: BaseSyntheticEvent | undefined) => Promise<void>;
  title: string;
  classNames?: string;
  children?: ReactNode;
}

export const AuthFormContainer: FC<AuthFromContainerProps> = ({
  onSubmit,
  title,
  classNames,
  children,
}) => {
  return (
    <form
      onSubmit={onSubmit}
      className={cn(
        'w-full h-full flex flex-col items-center gap-10 t-lg:gap-8',
        't-sm:max-w-[32.5rem] w-[32.5rem] px-2 py-8 mx-auto pt-10 justify-center',
        classNames,
      )}
    >
      <img src={LogoIcon} alt="" className="w-24 h-16 t-lg:w-44 t-lg:h-28 object-contain" />
      <div className="font-gilroy-500 text-xl t-lg:text-3xl t-lg:whitespace-nowrap text-center">
        {title}
      </div>
      {children}
      <div className="p-4 t-sm:hidden flex items-center justify-between mt-auto w-full">
        <Logo extraSmall />
        <div className="font-gilroy-400 text-extra-small">© 2023 Все права защищены</div>
      </div>
    </form>
  );
};
