import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'src/services/UserService';
import TariffService from 'src/services/TariffService';

export const fetchUser = createAsyncThunk('users/fetch', async (_, thunkAPI) => {
  try {
    const response = await UserService.getCurrentUserFromApi();

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить пользователя');
  }
});

export const fetchTariffs = createAsyncThunk('users/fetchTariffs', async (_, thunkAPI) => {
  try {
    const response = await TariffService.getTariffs();

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить тарифы');
  }
});
