import { FC, useState } from 'react';
import { Input } from 'src/components/kit/Input';
import { InputFile } from 'src/components/kit/InputFile';
import { Button } from 'src/components/kit/Button';
import { useForm } from 'react-hook-form';
import { toBase64 } from 'src/utils';
import { useAppDispatch } from 'src/hooks/useRedux';
import { fetchShops } from 'src/store/shops/actions';
import { ColorPicker } from 'src/components/kit/ColorPicker';
import { setGlobalError } from 'src/store/common';
import { LocalShopType, ShopType } from 'src/config/types';
import { defaultShopColors } from 'src/config/data';
import ShopService from 'src/services/ShopService';

import pdf from 'src/assets/documents/Инструкция.pdf';

import cn from 'classnames';

interface CreateShopFormProps {
  defaultValues?: ShopType;
  onSave?: () => void;
  classNames?: string;
}

export const CreateShopForm: FC<CreateShopFormProps> = ({ defaultValues, onSave, classNames }) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<LocalShopType>({
    mode: 'all',
    defaultValues: {
      ...defaultValues,
      color: defaultValues?.color?.replace('#', '') || defaultShopColors.color,
      buttonsColor: defaultValues?.buttonsColor?.replace('#', '') || defaultShopColors.buttonsColor,
      textColor: defaultValues?.textColor?.replace('#', '') || defaultShopColors.textColor,
    },
  });
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = handleSubmit(async data => {
    const { image, color, buttonsColor, textColor } = data;

    setLoading(true);

    let imageSrc = '';

    if (typeof image === 'string' && image) {
      imageSrc = image;
    }
    if (typeof image !== 'string' && image && (image as File)?.name) {
      imageSrc = (await toBase64(image)) as string;
    }

    const shop: LocalShopType = {
      ...(imageSrc && { image: imageSrc }),
      token: data.token,
      name: data.name,
      description: data.description,
      color: color.replace('#', ''),
      buttonsColor: buttonsColor.replace('#', ''),
      textColor: textColor.replace('#', ''),
    };

    if (defaultValues) {
      await ShopService.updateShop(shop, defaultValues.id)
        .then(async () => {
          if (onSave) onSave();
          await dispatch(fetchShops());
        })
        .catch(({ response }) => {
          dispatch(setGlobalError(`Ошибка!\n${response.data}`));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await ShopService.createShop(shop)
        .then(async () => {
          if (onSave) onSave();
          await dispatch(fetchShops());
        })
        .catch(({ response }) => {
          dispatch(setGlobalError(`Ошибка!\n${response.data}`));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const colors = [
    {
      name: 'color',
      title: 'Цвет фона',
      value: defaultValues?.color || defaultShopColors.color,
    },
    {
      name: 'buttonsColor',
      title: 'Цвет Кнопок',
      value: defaultValues?.buttonsColor || defaultShopColors.buttonsColor,
    },
    {
      name: 'textColor',
      title: 'Цвет текста кнопок',
      value: defaultValues?.textColor || defaultShopColors.textColor,
    },
  ];

  return (
    <form
      className={cn(
        'max-w-[32.5rem] w-full t-lg:w-[32.5rem] mx-auto flex flex-col gap-5 items-center',
        classNames,
      )}
      onSubmit={onSubmit}
    >
      <InputFile
        variant="logo"
        clearErrors={clearErrors}
        defaultValue={defaultValues?.image}
        register={register}
        setValue={setValue}
        data={{ placeholder: '', name: 'image', required: false }}
        errors={errors}
      />

      <Input
        register={register}
        data={{ placeholder: 'Название', name: 'name', required: true }}
        errors={errors}
      />

      <Input
        type="textarea"
        register={register}
        data={{ placeholder: 'Описание', name: 'description', required: true }}
        errors={errors}
      />
      {colors.map(input => (
        <div className="w-full" key={input.name}>
          <div className="font-gilroy-400 text-sm mb-2.5">{input.title}</div>
          <ColorPicker
            defaultValue={input?.value?.replace('#', '')}
            setValue={setValue}
            register={register}
            clearErrors={clearErrors}
            data={{ placeholder: 'Введите код', name: input.name, required: true }}
            errors={errors}
          />
        </div>
      ))}

      <div className="w-full">
        <div className="font-gilroy-400 text-sm mb-2.5">Токен магазина</div>
        <Input
          register={register}
          data={{ placeholder: 'Токен магазина', name: 'token', required: true }}
          errors={errors}
          toolTip={{
            name: 'про токен?',
            text: 'Токен магазина – это уникальный иденитификатор каждой из программ, использумых для автоматизации работы',
            href: pdf,
          }}
        />
      </div>

      <div className="flex t-sm:w-fit t-sm:flex-row-reverse flex-col w-full gap-5">
        <Button variant="turquoise" type="submit" disabled={loading}>
          Сохранить
        </Button>
        <Button
          variant="turquoise-outline"
          onClick={e => {
            e?.stopPropagation();
            onSave && onSave();
          }}
        >
          Отмена
        </Button>
      </div>
    </form>
  );
};
