import { FC, ReactNode, useEffect, useRef } from 'react';
import { addMask } from 'src/utils/phoneMask';
import { InputType } from 'src/config/types';
import { FieldErrors, RegisterOptions } from 'react-hook-form';
import { ToolTip } from 'src/components/kit/ToolTip';

import { ReactComponent as WarningIcon } from 'src/assets/icons/kit/warning.svg';

import cn from 'classnames';

interface InputProps {
  data: InputType;
  errors: FieldErrors;
  register: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  classNames?: string;
  registerOptions?: RegisterOptions;
  icon?: ReactNode;
  toolTip?: { name: string; text: string; href?: string };
  maxLength?: number;
  disabled?: boolean;
  id?: string;
  type?: 'input' | 'textarea' | 'password' | 'number' | 'money';
}

export const Input: FC<InputProps> = ({
  data,
  registerOptions,
  errors,
  register,
  icon,
  id,
  classNames,
  maxLength,
  disabled,
  toolTip,
  type = 'input',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isPhone = data.name === 'phone';
  const isError = !!errors?.[data.name];
  const error = errors?.[data.name]?.message || '';

  useEffect(() => {
    if (data.name === 'phone' && typeof document !== 'undefined') {
      addMask();
    }

    if (ref?.current && type === 'money') {
      const input = ref.current.querySelector('input');
      if (input) {
        input.addEventListener('keypress', e => {
          if (e.which === 46 && (input?.value?.match(/\./g)?.length || 0) >= 1) {
            e.preventDefault();
          }

          if (e.which === 44 && (input?.value?.match(/,/g)?.length || 0) >= 1) {
            e.preventDefault();
          }

          if ((e.which >= 48 && e.which <= 57) || e.which === 46 || e.which === 44) {
            return;
          }
          e.preventDefault();
        });
      }
    }
  }, []);

  const inputClassName = cn(
    {
      'text-pink-100 border-pink-100 bg-white': isError && !Boolean(disabled),
      'border-grey-300 placeholder-shown:bg-turquoise-500 shadow-black-200':
        !isError && !Boolean(disabled),
      'focus:shadow-turquoise-100 focus:bg-gradient-turquoise-200': !isError && !Boolean(disabled),
      'hover:shadow-turquoise-100 hover:bg-gradient-turquoise-200 ': !isError && !Boolean(disabled),
      'phone-mask': isPhone,
      'resize-none': type === 'textarea',
      'pr-16': Boolean(icon),
    },
    'w-full rounded-xl t-sm:px-8 px-5 py-3 text-lg font-gilroy-400 border-2',
    'placeholder-grey-100',
  );

  return (
    <div className={cn('flex flex-col gap-1 w-full relative', classNames)} ref={ref} id={id}>
      {type === 'textarea' ? (
        <textarea
          rows={3}
          placeholder={data.placeholder}
          maxLength={maxLength}
          {...register(data.name, {
            required: data.required,
          })}
          disabled={disabled}
          className={inputClassName}
        />
      ) : (
        <input
          placeholder={data.placeholder}
          maxLength={maxLength}
          type={type}
          {...register(data.name, {
            required: data.required,
            ...registerOptions,
          })}
          disabled={disabled}
          className={inputClassName}
        />
      )}
      {icon ? <div className="absolute top-5 right-8">{icon}</div> : null}
      {toolTip ? <ToolTip {...toolTip} classNames="mt-2 mx-auto" /> : null}
      {isError ? (
        <div className="flex items-center w-full gap-1 hook-form-error">
          <WarningIcon className="w-4 h-4 min-w-[1rem] fill-pink-100" />
          <div className="font-gilroy-400 text-pink-100 text-xs">
            {(error as string) || 'Поле не может быть пустым!'}
          </div>
        </div>
      ) : null}
    </div>
  );
};
