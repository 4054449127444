import { FC, Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from 'src/components/kit/Input';
import { Button } from 'src/components/kit/Button';
import { Title } from 'src/components/kit/Title';
import { fetchGroups } from 'src/store/groups/actions';
import { useAppDispatch } from 'src/hooks/useRedux';
import { generateUUID } from 'src/utils';
import { GroupType, LocalGroupType, SubGroupType, UpdateSubGroupType } from 'src/config/types';
import GroupService from 'src/services/GroupService';
import SubGroupService from 'src/services/SubGroupService';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import { ReactComponent as AddFileIcon } from 'src/assets/icons/kit/add-file.svg';

import cn from 'classnames';

interface GroupAddFormProps {
  onSave: () => void;
  defaultValues?: GroupType;
  shopId: string;
  classNames?: string;
}

export const GroupAddForm: FC<GroupAddFormProps> = ({
  onSave,
  defaultValues,
  shopId,
  classNames,
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LocalGroupType>({ mode: 'all', defaultValues });

  const [loading, setLoading] = useState<boolean>(false);
  const [subGroupsList, setSubGroupsList] = useState<Array<SubGroupType | number>>(
    defaultValues?.subgroups.length ? defaultValues?.subgroups : [],
  );

  const addSubGroup = e => {
    e.preventDefault();
    const lastNumber = subGroupsList[subGroupsList.length - 1];
    const nextNumber = typeof lastNumber === 'number' ? lastNumber + 1 : subGroupsList.length;
    setSubGroupsList(prev => [...prev, nextNumber]);
  };

  const removeSubGroup = (index: number) =>
    setSubGroupsList(prev => prev.map((subGroup, i) => (i === index ? -1 : subGroup)));

  const parseSubGroups = (groupId: string, subgroups: SubGroupType[]) => {
    const parsedSubgroups: UpdateSubGroupType[] = [];

    subgroups.forEach((subgroup, i) => {
      let flag: UpdateSubGroupType['flag'] = '';
      const defaultSubGroup = defaultValues?.subgroups?.[i];

      if (defaultSubGroup && subGroupsList[i] === -1) {
        flag = 'delete';
      }

      if (defaultSubGroup && subGroupsList[i] !== -1 && defaultSubGroup.name !== subgroup.name) {
        flag = 'update';
      }

      if (!defaultSubGroup && subGroupsList[i] !== -1 && subgroup.name) {
        flag = 'insert';
      }

      if (flag) {
        parsedSubgroups.push({
          id: defaultSubGroup ? defaultSubGroup.id : '',
          name: subgroup.name,
          shopId: shopId,
          groupId,
          flag,
        });
      }
    });

    return parsedSubgroups;
  };

  const onSubmit = handleSubmit(async data => {
    const { name, subgroups } = data;
    setLoading(true);
    let groupId: string;

    if (defaultValues) {
      await GroupService.updateGroup(defaultValues.id, name, shopId);
      groupId = defaultValues.id;
    } else {
      const { data } = await GroupService.createGroup(name, shopId);
      groupId = data;
    }
    if (subgroups) {
      await SubGroupService.universalSubGroup(parseSubGroups(groupId, subgroups));
    }

    setLoading(false);
    onSave();
    dispatch(fetchGroups(shopId));
  });

  return (
    <form
      onSubmit={onSubmit}
      className={cn(
        'max-w-[32.5rem] w-full t-lg:w-[32.5rem] mx-auto flex flex-col gap-5 items-center',
        classNames,
      )}
    >
      <Title title="Добавить группу" description="Для создания группы введите название" />
      <Input
        register={register}
        data={{ placeholder: 'Название', name: 'name', required: true }}
        errors={errors}
      />

      <Title title="Добавить подгруппы" description="Для создания подгрупп введите название" />

      {subGroupsList.map((subGroup, i) => {
        if (subGroup === -1) {
          return <Fragment key={`subgroup-add-${i}-${generateUUID()}`}></Fragment>;
        }

        const key = typeof subGroup === 'number' ? subGroup : i;

        return (
          <Input
            key={`subgroup-add-${i}-${generateUUID()}`}
            register={register}
            data={{
              placeholder: 'Название',
              name: `subgroups[${key}].name`,
              required: false,
            }}
            errors={errors}
            icon={
              <CloseIcon
                className="w-4 h-4 cursor-pointer fill-grey-100"
                onClick={() => removeSubGroup(key)}
              />
            }
          />
        );
      })}

      <Button variant="grey" size="small" classNames="ml-auto" onClick={addSubGroup}>
        Добавить новую подгруппу
        <AddFileIcon className="w-4 h-4 gill-white" />
      </Button>

      <div className="flex t-sm:w-fit t-sm:flex-row flex-col-reverse w-full gap-5">
        <Button
          variant="turquoise-outline"
          onClick={e => {
            e?.preventDefault();
            onSave();
          }}
        >
          Отмена
        </Button>
        <Button variant="turquoise" type="submit" disabled={loading}>
          Сохранить
        </Button>
      </div>
    </form>
  );
};
