import { FC } from 'react';
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo.svg';
import cn from 'classnames';

interface LogoProps {
  small?: boolean;
  extraSmall?: boolean;
  classNames?: string;
}

export const Logo: FC<LogoProps> = ({ small, extraSmall, classNames }) => {
  return (
    <div className={cn(classNames, 'flex items-center gap-2')}>
      <LogoIcon
        className={cn(
          {
            'w-12 h-8': Boolean(small),
            'w-20 h-14': !Boolean(small),
            'w-8 h-6': Boolean(extraSmall),
          },
          'object-contain',
        )}
      />

      <div
        className={cn(
          {
            'text-lg': Boolean(small),
            'text-3xl': !Boolean(small),
            'text-xs': Boolean(extraSmall),
          },
          'text-blue-100 font-gilroy-700',
        )}
      >
        BREEZE Bot
      </div>
    </div>
  );
};
