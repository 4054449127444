import { FC, useEffect, useRef, useState } from 'react';
import { InputType } from 'src/config/types';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { Input } from 'src/components/kit/Input';
import { HexColorPicker } from 'react-colorful';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { COLOR_VALIDATION_REGEXP } from 'src/config/regExp';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';

import cn from 'classnames';

interface ColorPickerProps {
  data: InputType;
  defaultValue?: string;
  errors: FieldErrors;
  setValue: UseFormSetValue<any>;
  clearErrors: (name?: any) => void;
  register: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  classNames?: string;
}

export const ColorPicker: FC<ColorPickerProps> = ({
  data,
  defaultValue,
  errors,
  clearErrors,
  register,
  setValue,
  classNames,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const pickerRef = useRef<HTMLDivElement | null>(null);

  const [color, setColor] = useState<string>(defaultValue || '');
  const [openPicker, setOpenPicker] = useState<boolean>(false);

  const handleOpenPicker = () => setOpenPicker(true);
  const handleClosePicker = () => setOpenPicker(false);

  useClickOutside(pickerRef, handleClosePicker);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const input = ref.current?.querySelector('input');

    if (!input) {
      return;
    }

    input?.addEventListener('input', e => {
      setValue(data.name, (e.target as HTMLInputElement)?.value);
      setColor((e.target as HTMLInputElement)?.value);
    });
  }, []);

  const handleChangeColor = (color: string) => {
    const newColor = color.replace('#', '');

    setColor(newColor);
    clearErrors(data.name);
    setValue(data.name, newColor);
  };

  return (
    <div
      ref={ref}
      className={cn(
        'w-full flex t-sm:flex-row items-center gap-2 t-sm:gap-5 relative h-12',
        classNames,
      )}
    >
      <div
        className={
          'h-full rounded-xl flex items-end justify-end px-4 py-2 ' +
          'cursor-pointer relative w-24 t-sm:min-w-[6rem]'
        }
        onClick={handleOpenPicker}
        style={{ backgroundColor: `#${color || '000'}` }}
      >
        <ArrowIcon className="fill-white w-3 h-3" />
      </div>
      <div className="text-grey-100 font-gilroy-400">или</div>
      <div className="relative w-full">
        <div className="absolute top-3.5 t-sm:left-5 left-2 flex items-center z-10">
          <div className="text-lg font-gilroy-400">#</div>
        </div>

        <Input
          data={data}
          errors={errors}
          register={register}
          maxLength={6}
          registerOptions={{
            validate: (val: string) => {
              if (!COLOR_VALIDATION_REGEXP.test(val)) {
                return 'Некорректный цвет. допустимые значения [0-9A-Fa-f]';
              }
            },
          }}
        />
      </div>
      {openPicker ? (
        <div ref={pickerRef} className="absolute z-50 top-full left-0">
          <HexColorPicker color={color} onChange={handleChangeColor} />
        </div>
      ) : null}
    </div>
  );
};
