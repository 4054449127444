import { FC, useEffect, useMemo, useState } from 'react';
import { DashboardContainer } from 'src/containers/DashboardContainer';
import { useParams } from 'react-router';
import { Button } from 'src/components/kit/Button';
import { Modal } from 'src/components/kit/Modal';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { fetchGroups } from 'src/store/groups/actions';
import { fetchCurrency, fetchProducts } from 'src/store/products/actions';
import { ProductBigCard } from 'src/components/kit/Cards/ProductBigCard';
import { ProductAddForm } from 'src/components/Forms/ProductAddForm';
import { Tag } from 'src/components/kit/Tag';
import { ProductSmallCard } from 'src/components/kit/Cards/ProductSmallCard';
import { ProductListCard } from 'src/components/kit/Cards/ProductListCard';
import { Loader } from 'src/components/Loader';
import { isEmpty } from 'src/utils';
import { productShowTypes } from 'src/config/data';
import { TabType } from 'src/config/types';
import { useTariff } from 'src/hooks/useTariff';

export const Products: FC = () => {
  const dispatch = useAppDispatch();
  const { shopId, groupId } = useParams();
  const {
    groups: { groups },
    shops: { shops },
    products: { products, isLoadingProducts, currency },
    common: { globalSearch },
  } = useAppSelector(state => state);

  const curTariff = useTariff();

  const [isOpenAddProductModal, setIsOpenAddProductModal] = useState<boolean>(false);
  const [isOpenTariffInfoModal, setIsOpenTariffInfoModal] = useState<boolean>(false);
  const [activeShowType, setActiveShowType] = useState<TabType>(productShowTypes[0]);

  const openAddProductModal = () => setIsOpenAddProductModal(true);
  const closeAddProductModal = () => setIsOpenAddProductModal(false);

  const openTariffInfoModal = () => setIsOpenTariffInfoModal(true);
  const closeTariffInfoModal = () => setIsOpenTariffInfoModal(false);

  const checkTariff = () => {
    if (!curTariff) {
      openTariffInfoModal();
      return;
    }
    let productsCount = 0;

    shops.forEach(shop => {
      productsCount += shop.productsCount;
    });

    if (productsCount >= +curTariff.goodsCount) {
      openTariffInfoModal();
      return;
    }

    openAddProductModal();
  };

  const group = useMemo(
    () => groups.find(group => group.id.toString() === groupId),
    [groups, groupId],
  );

  const filteredProducts = useMemo(() => {
    const res = {};

    Object.keys(products).map(key => {
      const elems = products[key].filter(prod =>
        prod.name?.toLowerCase()?.includes(globalSearch.toLowerCase()),
      );

      if (elems.length) {
        res[key] = elems;
      }
    });

    return res;
  }, [globalSearch, products]);

  useEffect(() => {
    (async () => {
      if (!groups.length) {
        await dispatch(fetchGroups(shopId || ''));
      }
      await dispatch(
        fetchProducts({
          shopId: shopId || '',
          groupIds: groupId || '',
        }),
      );
      if (!currency.length) {
        await dispatch(fetchCurrency());
      }
    })();
  }, []);

  return (
    <DashboardContainer
      search
      searchElement={
        <Button variant="turquoise" classNames="w-full t-lg:w-fit" onClick={checkTariff}>
          Добавить товар
        </Button>
      }
      underSearchElement={
        <div className="flex items-center gap-3">
          {productShowTypes.map(type => (
            <Tag
              key={`product-show-type-${type.value}`}
              onClick={setActiveShowType}
              tag={type}
              isActive={activeShowType.value === type.value}
            />
          ))}
        </div>
      }
      title={group?.name || ''}
      description="Здесь вы можете создавать, редактировать и удалять товары"
    >
      {isLoadingProducts ? <Loader classNames="mx-auto my-20 w-1/2 t-lg:w-1/3" /> : null}

      {!isLoadingProducts && isEmpty(products) && !globalSearch ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          У вас нет активных товаров
        </div>
      ) : null}

      {!isLoadingProducts && isEmpty(filteredProducts) && globalSearch ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          По вашему запросу ничего не найдено
        </div>
      ) : null}

      {!isLoadingProducts && !isEmpty(filteredProducts)
        ? Object.keys(filteredProducts)
            .sort((a, b) => +a - +b)
            .map(subgroupId => {
              const subgroup = group?.subgroups.find(
                subgroup => subgroup.id.toString() === subgroupId,
              );
              const curProducts = filteredProducts[subgroupId];

              return (
                <div key={`subgroup-product-${subgroupId}`} className="w-full mb-6 t-lg:mb-10">
                  {subgroup && curProducts ? (
                    <div className="mb-3 t-lg:mb-5 text-sm t-lg:text-lg font-gilroy-500">
                      {subgroup.name}
                    </div>
                  ) : null}

                  {activeShowType.value === 'big' ? (
                    <div className="grid grid-cols-1 t-sm:grid-cols-2 t-lg:grid-cols-3 d-xs:grid-cols-4 gap-5">
                      {curProducts.map((product, i) => (
                        <ProductBigCard key={`group-${product.id}-${i}`} data={product} />
                      ))}
                    </div>
                  ) : null}

                  {activeShowType.value === 'small' ? (
                    <div
                      className={
                        'grid grid-cols-2 m-md:grid-cols-3 t-sm:grid-cols-4 t-lg:grid-cols-6 ' +
                        'd-xs:grid-cols-7 d-lg:grid-cols-8 gap-5'
                      }
                    >
                      {curProducts.map((product, i) => (
                        <ProductSmallCard key={`group-${product.id}-${i}`} data={product} />
                      ))}
                    </div>
                  ) : null}

                  {activeShowType.value === 'list' ? (
                    <div className="grid grid-cols-1 gap-5">
                      {curProducts.map((product, i) => (
                        <ProductListCard key={`group-${product.id}-${i}`} data={product} />
                      ))}
                    </div>
                  ) : null}
                </div>
              );
            })
        : null}

      {isOpenAddProductModal ? (
        <Modal isOpen={isOpenAddProductModal} onClose={closeAddProductModal}>
          <ProductAddForm
            onSave={closeAddProductModal}
            shopId={shopId || ''}
            groupId={groupId || ''}
          />
        </Modal>
      ) : null}

      {isOpenTariffInfoModal ? (
        <Modal isOpen={isOpenTariffInfoModal} onClose={closeTariffInfoModal} mobileClose>
          <div className="mx-auto text-center mb-12 font-gilroy-500 text-xl mt-20 t-sm:mt-0">
            Превышен лимит по товарам!
          </div>
          <Button variant="turquoise" href="/dashboard/rates" classNames="mx-auto">
            К тарифам
          </Button>
        </Modal>
      ) : null}
    </DashboardContainer>
  );
};
