import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from 'src/components/kit/Input';
import { Button } from 'src/components/kit/Button';
import { InputFile } from 'src/components/kit/InputFile';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { fetchProducts } from 'src/store/products/actions';
import { prepareProductStats, scrollToErrors, toBase64 } from 'src/utils';
import { Select } from 'src/components/kit/Select';
import { setGlobalError } from 'src/store/common';
import { ProductImagesCarousel } from 'src/components/ProductImagesCarousel';
import { Modal } from 'src/components/kit/Modal';
import { StatsAddFrom } from 'src/components/Forms/StatsAddFrom';
import { AddButton } from 'src/components/AddButton';
import { Switch } from 'src/components/kit/Switch';
import { ProductStatsCarousel } from 'src/components/ProductStatsCarousel';
import { NUMBER_VALIDATION_REGEXP } from 'src/config/regExp';
import { LocalProductType, ProductType, SelectItemType, StatType } from 'src/config/types';
import ProductService from 'src/services/ProductService';

import cn from 'classnames';

interface ProductAddFormProps {
  onSave: () => void;
  defaultValues?: ProductType;
  shopId: string;
  groupId: string;
  classNames?: string;
}

export const ProductAddForm: FC<ProductAddFormProps> = ({
  onSave,
  defaultValues,
  shopId,
  groupId,
  classNames,
}) => {
  const dispatch = useAppDispatch();
  const {
    groups: { groups },
    products: { currency },
  } = useAppSelector(state => state);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    setError,
    formState: { errors },
  } = useForm<LocalProductType>({
    mode: 'all',
    defaultValues: defaultValues || { isActive: true },
  });
  const currencyList: SelectItemType[] =
    currency?.map(el => ({
      name: el.code,
      value: el.code,
    })) || [];

  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<(string | File)[]>(defaultValues?.carouselImages || []);
  const [isOpenStatsModal, setIsOpenStatsModal] = useState<number | null>(null);
  const [stats, setStats] = useState<StatType[]>(defaultValues?.parameters || []);
  const [isPercents, setIsPercents] = useState<boolean>(true);
  const [selectedCurrency, setSelectedCurrency] = useState<SelectItemType>(
    currencyList?.find(el => el.value === defaultValues?.currency) || currencyList[0],
  );

  const closeStatsModal = () => setIsOpenStatsModal(null);

  const noDots = str => str.replaceAll('.', ',');
  const noMark = str => str.replaceAll(',', '.');

  useEffect(() => {
    handleChangeDiscount(getValues('discount'));
    if (isPercents && +noMark(getValues('discount')) > 100) {
      setError('discount', { message: 'От 0 до 100!' });
    }
  }, [isPercents]);

  const handleChangeDiscount = (num: string) => {
    const oldPrice = +noMark(getValues('oldPrice'));
    const newNum = +noMark(num);

    if (!num) {
      setValue('newPrice', getValues('oldPrice') || '');
      return;
    }

    if ((isPercents && newNum > 100) || (!isPercents && newNum > oldPrice)) {
      return;
    }

    if (isPercents) {
      const val = (oldPrice * (1 - newNum / 100)).toFixed(2);
      setValue('newPrice', (val || '').toString());
      return;
    }

    if (!isPercents) {
      const val = (oldPrice - newNum).toFixed(2);
      setValue('newPrice', (val || '').toString());
      return;
    }
  };

  const handleChangeNewPrice = (num: string) => {
    const oldPrice = +noMark(getValues('oldPrice'));
    const discount = +noMark(getValues('discount'));
    const newNum = +noMark(num);

    if (!discount) {
      setValue('oldPrice', num || '');
      return;
    }

    if (newNum > oldPrice) {
      return;
    }

    if (isPercents) {
      const val = ((newNum * 100) / (100 - discount)).toFixed(2);
      setValue('oldPrice', (val || '').toString());
      return;
    }
    if (!isPercents) {
      const val = (newNum + discount).toFixed(2);
      setValue('oldPrice', (val || '').toString());
      return;
    }
  };

  const handleChangeOldPrice = (num: string) => {
    const discount = +noMark(getValues('discount'));
    const newNum = +noMark(num);

    if (!discount) {
      setValue('newPrice', num);
      return;
    }

    if (isPercents && num) {
      const val = (newNum * (1 - discount / 100)).toFixed(2);
      setValue('newPrice', (val || '').toString());
      return;
    }

    if (!isPercents && num) {
      const val = (newNum - discount).toFixed(2);
      setValue('newPrice', (val || '').toString());
      return;
    }
  };

  const group = useMemo(() => groups.find(group => group.id.toString() === groupId), [groups]);
  const subGroupsList = useMemo(() => {
    const list =
      group?.subgroups.map(subGroup => ({ name: subGroup.name, value: subGroup.id })) || [];
    const empty = { name: 'без подгруппы', value: '0' };

    return list ? [empty, ...list] : [empty];
  }, [group]);

  const defaultSubGroup = useMemo(
    () => subGroupsList.find(subGroup => subGroup.value.toString() === defaultValues?.subgroupId),
    [group],
  );

  const handleRemoveStat = (elIndex: number, statIndex: number) => {
    setStats(prev => {
      const tempList: StatType[] = [...prev];

      const tempItem = { ...tempList[statIndex] };

      if (tempItem.parameterValues[elIndex].flag === 'insert') {
        tempItem.parameterValues = tempItem.parameterValues.filter((_, i) => i !== elIndex);
      } else {
        tempItem.parameterValues = tempItem.parameterValues.map((el, i) =>
          i === elIndex ? { ...el, flag: 'delete' } : el,
        );
      }
      const isRemove = tempItem.parameterValues?.filter(el => el.flag !== 'delete');

      if (!isRemove.length) {
        tempItem.flag = 'delete';
      }
      tempList[statIndex] = tempItem;

      return tempList;
    });
  };

  const calcDiscount = (discount: string, oldPrice: string, newPrice: string) => {
    if (isPercents) {
      return discount ? discount : '0';
    }

    const curOldPrice = +noMark(oldPrice);
    const curNewPrice = +noMark(newPrice);

    return (100 - (curNewPrice / curOldPrice) * 100).toFixed(2).toString();
  };

  const onSubmit = handleSubmit(async data => {
    const { image, oldPrice, newPrice, subgroupId, discount } = data;

    if (oldPrice.replace(/[^.,]/g, '').length > 1) {
      setError('oldPrice', { message: 'Некорректное число!' });
      return;
    }

    if (newPrice.replace(/[^.,]/g, '').length > 1) {
      setError('newPrice', { message: 'Некорректное число!' });
      return;
    }

    setLoading(true);
    let imageSrc = '';

    if (image === defaultValues?.image) {
      imageSrc = image;
    } else {
      imageSrc = (await toBase64(image)) as string;
    }

    let imagesSrc: string[] = [];

    if (JSON.stringify(images) !== JSON.stringify(defaultValues?.image)) {
      for (const el of images) {
        if (typeof el === 'string') {
          imagesSrc.push(el);
        } else {
          const fileInBase64 = (await toBase64(el)) as string;
          imagesSrc.push(fileInBase64);
        }
      }
    } else {
      imagesSrc = images as string[];
    }

    const product: LocalProductType = {
      ...data,
      carouselImages: imagesSrc,
      image: imageSrc,
      oldPrice: noDots(oldPrice),
      newPrice: noDots(newPrice),
      discount: noDots(calcDiscount(discount, oldPrice, newPrice)),
      shopId: shopId,
      groupId: groupId,
      currency: selectedCurrency.value,
      subgroupId: subgroupId || '0',
    };

    const fetchProdsData = {
      shopId: shopId || '',
      groupIds: groupId || '',
    };

    try {
      let goodId = '';

      if (defaultValues) {
        const res = await ProductService.updateProduct(product);
        goodId = res.data;
      } else {
        await ProductService.createProduct(product)
          .then(({ data }) => {
            goodId = data;
          })
          .catch(({ response }) => {
            dispatch(setGlobalError(response.data));
          });
      }

      if (goodId && stats.length) {
        const params = await prepareProductStats(stats, goodId);
        await ProductService.addStats(params);
      }

      await dispatch(fetchProducts(fetchProdsData));
      setLoading(false);
      onSave();
    } catch (e) {
      setLoading(false);
      onSave();
    }
  });

  const handleClick = () => {
    if (loading) {
      return;
    }
    setTimeout(() => {
      scrollToErrors();
    }, 300);
  };

  return (
    <>
      <form
        onSubmit={onSubmit}
        className={cn(
          'max-w-[32.5rem] w-full t-lg:w-[32.5rem] mx-auto flex flex-col gap-5 items-center',
          classNames,
        )}
      >
        <InputFile
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          defaultValue={defaultValues?.image}
          data={{ placeholder: '', name: 'image', required: true }}
          errors={errors}
        />

        <ProductImagesCarousel images={images} setImages={setImages} />

        <Input
          register={register}
          data={{ placeholder: 'Название', name: 'name', required: true }}
          errors={errors}
        />

        <Input
          type="textarea"
          register={register}
          data={{ placeholder: 'Описание', name: 'description', required: false }}
          errors={errors}
        />

        {stats?.length
          ? stats.map((stat, i) => {
              if (stat.flag === 'delete') {
                return <Fragment key={`product-stat-show-${stat.parameterId}-${i}`} />;
              }

              return (
                <Fragment key={`product-stat-show-${stat.parameterId}-${i}`}>
                  <div className="text-lg font-gilroy-400 w-full">{stat.parameterName}</div>

                  <ProductStatsCarousel
                    handleRemove={index => handleRemoveStat(index, i)}
                    stat={stat.parameterValues}
                    statId={stat.parameterName}
                    openStatsModal={() => setIsOpenStatsModal(i)}
                  />
                </Fragment>
              );
            })
          : null}

        <AddButton
          onClick={() => setIsOpenStatsModal(stats.length)}
          name="Добавить характеристики"
        />

        <div className="flex w-full gap-4 grid grid-cols-2">
          <Input
            classNames="w-full"
            register={register}
            data={{ placeholder: 'Цена', name: 'oldPrice', required: true }}
            errors={errors}
            type={'money'}
            registerOptions={{
              validate: (val: string) => {
                handleChangeOldPrice(val);
                if (!NUMBER_VALIDATION_REGEXP.test(val)) {
                  return 'Некорректное число!';
                }
              },
            }}
          />
          <Select
            classNames="w-full h-14"
            data={{
              placeholder: 'Валюта',
              name: 'currency',
              list: currencyList,
            }}
            setStateValue={setSelectedCurrency}
            value={selectedCurrency}
          />
        </div>

        <div className="flex w-full gap-4 grid grid-cols-2">
          <Input
            classNames="w-full"
            register={register}
            data={{ placeholder: 'Скидка', name: 'discount' }}
            errors={errors}
            type="money"
            registerOptions={{
              validate: (val: string) => {
                handleChangeDiscount(val);
                if (isPercents && +val > 100) {
                  return 'От 0 до 100!';
                }

                if (!isPercents && +val > +getValues('oldPrice')) {
                  return 'Не может быть больше цены';
                }

                if (!NUMBER_VALIDATION_REGEXP.test(val)) {
                  return 'Некорректное число!';
                }
              },
            }}
          />
          <div className="w-full flex items-center justify-between h-14">
            <div className="text-black-100 font-gilroy-500 text-sm w-24">{`Cкидка, ${
              isPercents ? '%' : 'руб.'
            }`}</div>
            <Switch
              name="currencyType"
              variant="money"
              isChecked={isPercents}
              setValue={setIsPercents}
            />
          </div>
        </div>

        <div className="w-full">
          <div className="mb-2 font-gilroy-400 text-sm">Цена со скидкой</div>
          <Input
            classNames="w-full"
            register={register}
            data={{ placeholder: 'Цена со скидкой', name: 'newPrice' }}
            errors={errors}
            type="money"
            registerOptions={{
              validate: (val: string) => {
                handleChangeNewPrice(val);
                if (!NUMBER_VALIDATION_REGEXP.test(val)) {
                  return 'Некорректное число!';
                }
              },
            }}
          />
        </div>

        {subGroupsList.length ? (
          <Select
            data={{
              placeholder: 'Выбрать подгруппу',
              name: 'subgroupId',
              list: subGroupsList,
              defaultValue: defaultSubGroup || subGroupsList[0],
            }}
            setValue={setValue}
            register={register}
          />
        ) : null}

        <div className="flex t-sm:w-fit t-sm:flex-row flex-col-reverse w-full gap-5">
          <Button
            variant="turquoise-outline"
            onClick={e => {
              e?.stopPropagation();
              onSave();
            }}
          >
            Отмена
          </Button>
          <Button variant="turquoise" type="submit" disabled={loading} onClick={handleClick}>
            Сохранить
          </Button>
        </div>
      </form>

      {typeof isOpenStatsModal === 'number' ? (
        <Modal isOpen={typeof isOpenStatsModal === 'number'} onClose={closeStatsModal}>
          <StatsAddFrom
            statIndex={isOpenStatsModal}
            onClose={closeStatsModal}
            setStats={setStats}
            stats={stats}
          />
        </Modal>
      ) : null}
    </>
  );
};
