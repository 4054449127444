import { FC, useState } from 'react';
import { ProductType } from 'src/config/types';
import { GradientBorder } from 'src/components/kit/GradientBorder';
import { Button } from 'src/components/kit/Button';
import { numberWithSpaces } from 'src/utils';
import { ProductAddForm } from 'src/components/Forms/ProductAddForm';
import { Modal } from 'src/components/kit/Modal';
import { Discount } from 'src/components/kit/Discount';
import { BasketIconButton } from 'src/components/IconButton/BasketIconButton';
import { DeleteModal } from 'src/components/Modals/DeleteModal';
import { useAppDispatch } from 'src/hooks/useRedux';
import { useParams } from 'react-router';
import { fetchProducts } from 'src/store/products/actions';
import { AppToolTip } from 'src/components/AppToolTip';
import ProductService from 'src/services/ProductService';

import { ReactComponent as EditIcon } from 'src/assets/icons/kit/edit.svg';

import cn from 'classnames';

interface ProductBigCardProps {
  data: ProductType;
  classNames?: string;
}

export const ProductBigCard: FC<ProductBigCardProps> = ({ data, classNames }) => {
  const { shopId, groupId } = useParams();
  const dispatch = useAppDispatch();

  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const openAddModal = () => setIsOpenAddModal(true);
  const closeAddModal = () => setIsOpenAddModal(false);

  const openDeleteModal = () => setIsOpenDeleteModal(true);
  const closeDeleteModal = () => setIsOpenDeleteModal(false);

  const handleDeleteProduct = async () => {
    await ProductService.deleteProduct(data.id, shopId || '');
    await dispatch(fetchProducts({ shopId: shopId || '', groupIds: groupId || '' }));
  };

  return (
    <>
      <GradientBorder
        borderWidth="2px"
        borderGradient={data.isActive ? 'bg-gradient-turquoise-300' : 'bg-grey-300 hover:bg-white'}
        classNames="rounded-xl"
      >
        <div
          className={cn(
            {
              'bg-white': data.isActive,
              'hover:bg-white hover:shadow-turquoise-100': !data.isActive,
            },
            'px-5 py-6 rounded-xl group transition-full w-full h-full',
            classNames,
          )}
        >
          <div className="w-full h-40 mb-4 relative">
            <img
              src={data.image}
              alt=""
              className={cn(
                { 'opacity-30 group-hover:opacity-100': !data.isActive },
                'object-cover w-full h-full transition-full',
              )}
            />
            <BasketIconButton
              image="basket"
              onClick={openDeleteModal}
              classNames="absolute top-0 right-0"
            />
            <Discount discount={data.discount} size="lg" classNames="absolute bottom-0 right-0" />
          </div>

          <div className="flex w-full justify-between gap-4 mb-4">
            <div className="font-gilroy-500" style={{ width: 'calc(100% - 48px)' }}>
              <AppToolTip
                classNames="text-sm truncate w-full"
                id={`product-name-${data.id}-tooltip`}
                text={data.name}
              />

              <AppToolTip
                classNames="text-small text-grey-100 truncate w-full"
                id={`product-description-${data.id}-tooltip`}
                text={data.description}
              />
            </div>
            <Button
              variant="turquoise-icon"
              classNames={cn({ 'bg-white-100': data.isActive, 'bg-white': !data.isActive })}
              size="icon"
              onClick={openAddModal}
            >
              <EditIcon className="w-4 h-4" />
            </Button>
          </div>

          <div className="flex items-center justify-between">
            <div>
              <div className="font-gilroy-400 line-through text-grey-100 text-sm-1 h-3.5">
                {data.oldPrice !== data.newPrice
                  ? `${numberWithSpaces(data.oldPrice)} ${data.currency || '₽'}`
                  : ''}
              </div>
              <div
                className={cn(
                  {
                    'text-turquoise-100': data.isActive,
                    'text-grey-100 group-hover:text-turquoise-100': !data.isActive,
                  },
                  'font-gilroy-700 text-lg-1 transition-full',
                )}
              >{`${numberWithSpaces(data.newPrice)} ${data.currency || '₽'}`}</div>
            </div>
          </div>
        </div>
      </GradientBorder>

      {isOpenDeleteModal ? (
        <Modal isOpen={isOpenDeleteModal} onClose={closeDeleteModal}>
          <DeleteModal
            text="Вы точно хотите удалить товар?"
            handleDelete={handleDeleteProduct}
            handleClose={closeDeleteModal}
          />
        </Modal>
      ) : null}

      {isOpenAddModal ? (
        <Modal isOpen={isOpenAddModal} onClose={closeAddModal}>
          <ProductAddForm
            defaultValues={data}
            onSave={closeAddModal}
            shopId={data.shopId}
            groupId={data.groupId}
          />
        </Modal>
      ) : null}
    </>
  );
};
