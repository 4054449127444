import { RouteType } from 'src/config/types';
import { Login } from 'src/pages/Login';
import { Registration } from 'src/pages/Registration';
import { ResetPassword } from 'src/pages/ResetPassword';
import { Shops } from 'src/pages/Dashboard/Shops';
import { Profile } from 'src/pages/Dashboard/Profile';
import { Rates } from 'src/pages/Dashboard/Rates';
import { Groups } from 'src/pages/Dashboard/Groups';
import { Products } from 'src/pages/Dashboard/Products';
import { Support } from 'src/pages/Dashboard/Support';
import { Orders } from 'src/pages/Dashboard/Orders';
import { Processing } from 'src/pages/Dashboard/Payments/Processing';
import { Rejected } from 'src/pages/Dashboard/Payments/Rejected';
import { StoreFiltering } from 'src/pages/Dashboard/StoreFiltering';
import { ChangePassword } from 'src/pages/ChangePassword';

export const routes: RouteType[] = [
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/registration',
    element: <Registration />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/changepwd',
    element: <ChangePassword />,
  },
];

export const protectedRoutes: RouteType[] = [
  {
    path: '/dashboard/shops',
    element: <Shops />,
  },
  {
    path: '/dashboard/shops/:shopId',
    element: <Groups />,
  },
  {
    path: '/dashboard/shops/:shopId/:groupId',
    element: <Products />,
  },
  {
    path: '/dashboard/links/:shopId',
    element: <Shops />,
  },
  {
    path: '/dashboard/profile',
    element: <Profile />,
  },
  {
    path: '/dashboard/rates',
    element: <Rates />,
  },
  {
    path: '/dashboard/orders',
    element: <Orders />,
  },
  {
    path: '/dashboard/support',
    element: <Support />,
  },
  {
    path: '/dashboard/payment/processing',
    element: <Processing />,
  },
  {
    path: '/dashboard/payment/rejected',
    element: <Rejected />,
  },
  {
    path: '/dashboard/store-filtering',
    element: <StoreFiltering />,
  },
];
