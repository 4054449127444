import { FC, useEffect, useMemo, useState } from 'react';
import { DashboardContainer } from 'src/containers/DashboardContainer';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { fetchOrderStatuses } from 'src/store/orders/actions';
import { OrderCard } from 'src/components/Cards/OrderCard';
import { EditOrderModal } from 'src/components/Modals/EditOrderModal';
import { Loader } from 'src/components/Loader';
import { fetchProducts } from 'src/store/products/actions';
import { setIsLoadingOrders } from 'src/store/orders';
import { useSearchParams } from 'react-router-dom';
import { OrderType } from 'src/config/types';

export const Orders: FC = () => {
  const dispatch = useAppDispatch();
  const {
    common: { globalSearch },
    shops: { isLoadingShops, selectedShop },
    orders: { orders, isLoadingOrders, orderStatuses },
  } = useAppSelector(state => state);

  const [isEditOpen, setIsEditOpen] = useState<OrderType | null>(null);

  const handleOpenEdit = order => setIsEditOpen(order);
  const handleCloseEdit = () => setIsEditOpen(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const orderId = searchParams.get('orderId');
    if (orderId && orders?.length) {
      const order = orders.find(order => order.id.toString() === orderId.toString());
      setIsEditOpen(order || null);
    }
  }, [orders]);

  const getOrders = async () => {
    if (!isLoadingShops && !selectedShop) {
      dispatch(setIsLoadingOrders(false));
      return;
    }

    if (!selectedShop) {
      return;
    }

    if (!orderStatuses) {
      await dispatch(fetchOrderStatuses());
    }

    await dispatch(fetchProducts({ shopId: selectedShop?.id, groupIds: '' }));
  };

  const filteredOrders = useMemo(
    () =>
      orders?.length
        ? orders?.filter(order => order.id.toString().includes(globalSearch.toLowerCase()))
        : [],
    [globalSearch, orders],
  );

  useEffect(() => {
    (async () => {
      await getOrders();
    })();
  }, [selectedShop]);

  return (
    <DashboardContainer
      search
      title="Мои заказы"
      description="Здесь вы можете заказы ваших магазинов"
    >
      {isLoadingOrders ? <Loader classNames="mx-auto my-20 w-1/2 t-lg:w-1/3" /> : null}

      {!isLoadingOrders && filteredOrders.length ? (
        <div className="flex flex-col gap-5">
          {filteredOrders?.map(order => (
            <OrderCard key={`order-${order.id}`} order={order} openEdit={handleOpenEdit} />
          ))}
        </div>
      ) : null}

      {!isLoadingOrders && orders.length && !filteredOrders.length && globalSearch ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          По вашему запросу ничего не найдено
        </div>
      ) : null}

      {!isLoadingOrders && !orders.length ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          У вас нет активных заказов
        </div>
      ) : null}

      <EditOrderModal order={isEditOpen} onClose={handleCloseEdit} />
    </DashboardContainer>
  );
};
